import React from "react";
import { css } from "@emotion/core";
import { TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = () => {
  const [state, setState] = React.useState({});
  const [hide, setHide] = React.useState(true);
  const [fields, setFields] = React.useState({
    name: null,
    email: null,
    message: null,
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setFields({ ...fields, [e.target.name]: e.target.value.trim() });
    setHide(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    if (isFormValid()) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      })
      .then(() => {
        setHide(false);
        reset();
      })
      .catch((error) => alert(error));
    }
    else {
      setFields({
        name: fields.name === null ? "" : fields.name,
        email: fields.email === null ? "" : fields.email,
        message: fields.message === null ? "" : fields.message,
      });
      setHide(true);
    }
  };

  const reset = () => {
    document.getElementById("contactForm").reset();
    setFields(
      {
        name: null,
        email: null,
        message: null,
      }
    );
  };

  const isFormValid = () => {
    return fields.name !== null && fields.name !== "" &&
      fields.message !== null && fields.message !== "" &&
      isValidEmail(fields.email) === "";
  };

  const isValidEmail = (email) => {
    let errorMessage = "";
    if (email != null) {
      if (email.trim() === "")
      {
        errorMessage = "Email is empty.";
      }
      else if (!(/\S+@\S+\.\S+/).test(email)) {
        errorMessage = "Email is invalid.";
      }
    }
    return errorMessage;
  };

  const handleAlert = (e) => {
    e.preventDefault();
    setHide(!hide);
  }

  return (
    <form
      id="contactForm"
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      autoComplete="off"
      noValidate
      
      css={css`
        margin: 0 auto;
        width: 100%;
        max-width: 650px;
        
        textarea {
          resize: vertical;
        }

        .MuiAlert-root {
          margin-bottom: 20px;

          &.hide {
            display: none;
          }
        }

        .MuiFormControl-root {
          width: 100%;
          margin-bottom: 20px;
        }
        label.Mui-focused {
          color: #0d6ec3;
        }
        .MuiOutlinedInput-root {
          & fieldset {
            border-width: 1px;
            border-color: rgba(0,0,0,.8);
          }
          &:hover fieldset {
            border-color: rgba(0,0,0,.8);
          }
          &.Mui-focused fieldset {
            border-color: #0d6ec3;
          }
        }

        .MuiFormControl-root {
          &.error {
            .MuiFormLabel-root {
              color: #f04444;
            }
            .MuiFormHelperText-root {
              color: #f04444;
            }
            .MuiOutlinedInput-root {
              label.Mui-focused {
                color: #f04444;
              }
              & fieldset {
                border-width: 1px;
                border-color: #f04444;
              }
              &:hover fieldset {
                border-color: #f04444;
              }
              &.Mui-focused fieldset {
                border-width: 2px;
                border-color: #f04444;
              }
            }
          }
        }
      `}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <div hidden>
        <label>Don’t fill this out: <input name="bot-field" onChange={handleChange} /></label>
      </div>

      <Alert variant="filled" severity="success" onClose={handleAlert} className={hide === true ? "hide" : ""}>
        Thank you, your message was sent!
      </Alert>

      <div>
        <TextField
          name="name"
          id="name"
          label="Name"
          variant="outlined"
          onChange={handleChange}
          onBlur={handleChange}
          className={fields.name !== null && fields.name.trim() === "" ? "error" : ""}
          helperText={fields.name !== null && fields.name.trim() === "" ? "Name is empty." : ""}
          required />
      </div>
      <div>
        <TextField
          type="email"
          name="email"
          id="email"
          label="Email"
          variant="outlined"
          onChange={handleChange}
          onBlur={handleChange}
          className={fields.email !== null && isValidEmail(fields.email) !== "" ? "error" : ""}
          helperText={isValidEmail(fields.email)}
          required />
      </div>
      <div>
        <TextField
          id="message"
          name="message"
          label="Message"
          multiline
          rows={5}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleChange}
          className={fields.message !== null && fields.message.trim() === "" ? "error" : ""}
          helperText={fields.message !== null && fields.message.trim() === "" ? "Message is empty." : ""}
          required
        />
      </div>
      <div css={css`text-align: center;`}>
        <button type="submit" css={css`
          border: none;
          outline: none;
          display: inline-block;

          cursor: pointer;

          padding: 1.25rem 2rem;

          text-transform: uppercase;
          text-decoration: none;
          color: #fff;

          font-weight: 700;
          letter-spacing: 2px;

          box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1);

          border-radius: 8px;

          /* gradient transition */
          background-size: 100%;
          background-image: linear-gradient(45deg, #f04444 0%, #0d6ec3 100%);
          position: relative;
          z-index: 100;
          &:before {
            background-image: linear-gradient(45deg, #0d6ec3 0%, #f04444 100%);
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            top: 0; left: 0;
            opacity: 0;
            width: 100%;
            z-index: -100;
            border-radius: 8px;
            transition: opacity 0.25s;
          }

          &:hover {
            &:before {
              opacity: 1;
            }
          }

          &.error {
            opacity: 0.5;
            cursor: not-allowed;
          }
        `}
        className={isFormValid() ? "" : "error"}
        >
          Send Message
        </button>
      </div>
    </form>
  )
}

export default () => {
  return (
    <section>
      <h2 id="contact"
        css={css`
          max-width: 650px;
          margin-left: auto;
          margin-right: auto;
        `}
      >Contact Me</h2>
      <ContactForm />
    </section>
  );
};

import React from "react";
import { css } from "@emotion/core";

export default ({idPrefix, startColor, stopColor, children}) => (
  <div css={
    css`
      display: inline;

      .svg-settings {
        /* Hiding this content since it's just settings. See - https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
        position: absolute !important;
        height: 1px; width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
      }

      .fill-gradient-linear path {
        fill: url(#${idPrefix}-linear);
      }

      a:hover svg path {
        fill: url(#${idPrefix}-linear-hover);
      }
      
      .linear-stop1 {
        stop-color: ${startColor};
      }
      
      .linear-stop2 {
        stop-color: ${stopColor};
      }
    `
  }>
    {children}
    
    { /* setting gradients for SVGs */ }
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className="svg-settings">
      <defs>
        <linearGradient id={`${idPrefix}-linear`}>
          <stop className="linear-stop1" offset="0%"></stop>
          <stop className="linear-stop2" offset="100%"></stop>
        </linearGradient>
        <linearGradient id={`${idPrefix}-linear-hover`}>
          <stop className="linear-stop2" offset="0%"></stop>
          <stop className="linear-stop1" offset="100%"></stop>
        </linearGradient>
      </defs>
    </svg>
  </div>
);
import React from "react";
import { css } from "@emotion/core";
import Skills from "../components/skills";

export default ({data}) => (
  <section>
    <h2 id="about">
      {data.frontmatter.label}
    </h2>

    <div
      css={css`
        @media (min-width: 900px) {
          display: flex;
        }
      `}>
      <div
        css={css`
          @media (min-width: 900px) {
            width: 55%;
          }
        `}
        >
          <h3>Career and Education</h3>
          <div dangerouslySetInnerHTML={{
            __html: data.html,
          }}/>
      </div>
      <Skills skills={data.frontmatter.skills} />
    </div>
  </section>
);
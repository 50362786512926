import React, { Component } from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { css } from "@emotion/core";
import { Parallax, Background } from "react-parallax";

import AboutSection from "../sections/about";
import ProjectsSection from "../sections/projects";
import ContactSection from "../sections/contact";
import MapsSection from "../sections/gmap";
import QuoteSection from "../sections/quote";
import FactsSection from "../sections/facts";

import SEO from "../components/seo";
import Footer from "../components/footer";
import Nav from "../components/nav";

import Container from "../components/container";

import { library } from "@fortawesome/fontawesome-svg-core";
import { config } from "@fortawesome/fontawesome-svg-core"
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faCoffee, faBriefcase, faBars,
  faLaptop, faGlobe, faKeyboard
} from "@fortawesome/fontawesome-free-solid";
import "@fortawesome/fontawesome-svg-core/styles.css";

// add these icons to the library so that there isn't any flickering on load.
library.add(
  faCoffee, faBriefcase, faBars,
  faLaptop, faGlobe, faKeyboard,
  faTwitter, faLinkedin
);
config.autoAddCss = false;

export const query = graphql`
  query {
    main: markdownRemark(fields: { slug: { eq: "/main/" } }) {
      frontmatter {
        title
        subtitle
        background {
          childImageSharp {
            fluid(
              quality: 90,
              traceSVG: {
                color: "rgb(3, 80, 165)",
                threshold: 90
              },
              toFormat: JPG,
              duotone: { highlight: "#0d6ec3", shadow: "#0b599e", opacity: 75 }
            ) {
              src
              tracedSVG
            }
          }
        }
        primary
        secondary
      }
    }
    about: markdownRemark(fields: { slug: { eq: "/about/" } }) {
      frontmatter {
        label
        skills {
          skill {
            name
            value
          }
        }
      }
      html
    }
    facts: markdownRemark(fields: { slug: { eq: "/facts/" } }) {
      frontmatter {
        background {
          childImageSharp {
            fluid(
              quality: 90,
              traceSVG: {
                color: "rgb(3, 80, 165)",
                threshold: 90
              },
              toFormat: JPG,
              duotone: { highlight: "#0d6ec3", shadow: "#0b599e", opacity: 50 }
            ) {
              src
              tracedSVG
            }
          }
        }
        facts {
          fact {
            icon
            name
            value
          }
        }
      }
    }
    projects: markdownRemark(fields: { slug: { eq: "/projects/" } }) {
      frontmatter {
        label
        projects {
          project {
            name
            description
            link
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    quote: markdownRemark(fields: { slug: { eq: "/quote/" } }) {
      frontmatter {
        quote
        author
        background {
          childImageSharp {
            fluid(
              quality: 90,
              traceSVG: {
                color: "rgb(3, 80, 165)",
                threshold: 90
              },
              toFormat: JPG,
              duotone: { highlight: "#0d6ec3", shadow: "#0b599e", opacity: 50 }
            ) {
              src
              tracedSVG
            }
          }
        }
      }
    }
    location: markdownRemark(fields: { slug: { eq: "/location/" } }) {
      frontmatter {
        city
        state
        location
      }
    }
    socials: markdownRemark(fields: { slug: { eq: "/socials/" } }) {
      frontmatter {
        linkedin
        twitter
      }
    }
  }
`;

class TypeText extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currPos: 0,
    };

    this.typeWriter = this.typeWriter.bind(this);
  }

  componentDidMount() {
    setTimeout(this.typeWriter, this.props.speed);
  }

  typeWriter() {
    if (this.state.currPos < this.props.text.length) {
      this.setState((prevState) => ({
        currPos: ++prevState.currPos
      }));

      const speed = (Math.floor(Math.random() * 100) + 50); // 50 - 150 speed
      setTimeout(this.typeWriter, speed);
    }
  }

  render() {
    return (
      <span>
        {this.props.text.substring(0, this.state.currPos)}
      </span>
    );
  }
};

const IndexPage = ({data}) => {
  const mainTitle = data.main.frontmatter.title;
  const mainSubtitle = data.main.frontmatter.subtitle;
  const mainPrimaryButtonText = data.main.frontmatter.primary;
  const mainSecondaryButtonText = data.main.frontmatter.secondary;
  const mainBackgroundImage = data.main.frontmatter.background.childImageSharp.fluid;
  const quoteBackgroundImage = data.quote.frontmatter.background.childImageSharp.fluid;
  const factsBackgroundImage = data.facts.frontmatter.background.childImageSharp.fluid;

  const socials = data.socials.frontmatter;

  const headerBaseStyles = css`
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 1rem;
    text-shadow: .1875rem .1875rem .1875rem rgba(0,0,0,.1);
  `;
  
  return (
    <div id="main">
      <SEO />
      <Nav data={socials} />

      <div css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          
          padding: 8px;
          width: 100%;
          height: 100vh;
          min-height: 650px;
        
          background-color: #0d6ec3;
          background-image: url("${mainBackgroundImage.src}"), url("${mainBackgroundImage.tracedSVG}");
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          background-attachment: fixed;
        `}
      >
        <div css={css`
            text-align: center;
            div.row {
              padding: 8px;
            }
          `}
        >
          <div className="row">
            <h1 css={css`
                ${headerBaseStyles}
                letter-spacing: 3px;
                
                font-weight: 700;

                @media (max-width: 599px) {
                  font-size: 2em;
                }
                @media (min-width: 600px) {
                  font-size: 3.052em;
                }
              `}
            >
              {mainTitle}
            </h1>
          </div>

          <div className="row">
            <h2 css={css`
                ${headerBaseStyles}
                letter-spacing: 6px;
                font-size: 1.25em;
                font-weight: 500;
                text-align: left;
                padding-left: 4px;
                min-height: 23px;

                @media (max-width: 599px) {
                  font-size: 1em;
                  line-height: 1.25em;
                  min-height: 40px;
                }
                @media (min-width: 600px) {
                  font-size: 1.25em;
                }

                @media (min-width: 0px) and (max-width: 959px) {
                  text-align: center;
                  padding-left: 0;
                }
              `}
            >
              <TypeText speed={100} text={mainSubtitle} />
            </h2>
          </div>

          <div className="row">
            <div css={css`
                a {
                  display: inline-block;
                  margin: 8px 16px;
                  padding: 1.25rem 2rem;
                  text-decoration: none;
                  color: #fff;
               
                  font-weight: 700;
                  letter-spacing: 2px;

                  border-radius: 8px;
                  box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1);

                  @media (min-width: 0px) and (max-width: 959px) {
                    margin: 8px 0;
                  }
                  @media (max-width: 599px) {
                    width: 75%;
                  }
                  @media (min-width: 600px) and (max-width: 959px) {
                    width: 55%;
                  }
                }

                a.secondary {
                  border: 1px solid #fff;
                  
                  background: transparent;

                  transition: 0.25s background, 0.25s color;

                  &:hover {
                    color: #0d6ec3;
                    background: #fff;
                  }
                }

                a.primary {
                  padding: calc(1.25rem + 1px) calc(2rem + 1px);
             
                  color: #fff;

                  background-size: 100%;
                  background-image: linear-gradient(45deg, #f04444 0%, #0d6ec3 100%);
                  position: relative;
                  z-index: 100;
                  &:before {
                    background-image: linear-gradient(45deg, #0d6ec3 0%, #f04444 100%);
                    content: "";
                    display: block;
                    height: 100%;
                    position: absolute;
                    top: 0; left: 0;
                    opacity: 0;
                    width: 100%;
                    z-index: -100;
                    border-radius: 8px;
                    transition: opacity 0.25s;
                  }
                  &:hover {
                    &:before {
                      opacity: 1;
                    }
                  }
                }
              `}
            >
              <AnchorLink to="/#contact" className="primary" title={mainPrimaryButtonText} stripHash />
              <AnchorLink to="/#projects" className="secondary" title={mainSecondaryButtonText} stripHash />
            </div>
          </div>

          <AnchorLink to="/#about" title="Scroll down" stripHash>
            <div css={css`
              position: absolute;
              bottom: 32px;

              @media screen and (max-width: 767px), screen and (max-height: 700px) {
                display: none;
              }

              @media (min-width: 960px) {
                bottom: 64px;
              }

              left: 50%;
              transform: translateX(-50%);

              width: 26px;
              height: 40px;
              border: 2px solid #fff;
              border-radius: 14px;

              cursor: pointer;

              transition: 0.25s background;

              box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1);

              &:hover {
                background: white;

                span {
                  background: #0d6ec3;
                }
              }

              span {
                position: absolute;

                left: 50%;
                top: 50%;
                transform: translateX(-50%);
                
                height: 6px;
                width: 6px;
                background: #fff;
                border-radius: 50%;

                transition: 0.25s background;

                animation: dot-animation 1.8s infinite ease-in-out;

                @keyframes dot-animation {
                  0% {
                    top: calc(50% - 8px);
                  }
                  40% {
                    top: 50%;
                  }
                  100% {
                    top: calc(50% - 8px);
                  }
                }
              }
            `}>
              <span></span>
            </div>
          </AnchorLink>
        </div>
      </div>

      <div css={css`
        line-height: 1.8;
      `}>
        <Container bgColor="#fff">
          <AboutSection data={data.about} />
        </Container>

        <div css={css`
          background: #ccc;
        `}>
          <Parallax strength={700}>
            <Container>
              <div css={css`
                @media (min-width: 600px) {
                  padding: 20px 0;
                }
              `}>
                <FactsSection data={data.facts} />
              </div>
            </Container>
            <Background>
              <div css={css`
                width: 100vw;
                height: 100vh;

                @media (max-width: 959px) {
                  min-height: 1300px;
                }
                @media (min-width: 960px) {
                  min-height: 900px;
                }

                background-image: url("${factsBackgroundImage.src}"), url("${factsBackgroundImage.tracedSVG}");
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
              `}></div>
            </Background>
          </Parallax>
        </div>
        
        <Container bgColor="#fff">
          <ProjectsSection data={data.projects} />
        </Container>

        <div css={css`
          background: #ccc;
        `}>
          <Parallax strength={700}>
            <Container>
              <div css={css`
                @media (min-width: 600px) {
                  padding: 50px 0;
                }
              `}>
                <QuoteSection data={data.quote} />
              </div>
            </Container>
            <Background>
              <div css={css`
                width: 100vw;
                height: 100vh;
                min-height: 900px;
                background-image: url("${quoteBackgroundImage.src}"), url("${quoteBackgroundImage.tracedSVG}");
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
              `}></div>
            </Background>
          </Parallax>
        </div>

        <Container bgColor="#fff">
          <ContactSection />
        </Container>

        <div>
          <MapsSection data={data.location} />
          <div css={css`
            @media (max-width: 959px) {
              margin-bottom: 300px;
            }
            @media (min-width: 960px) {
              margin-bottom: 380px;
            }
          `} />
          <div css={css`
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: -1;
          `}>
            <Container bgColor="#f1f1f1">
              <Footer data={socials} />
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;

import React from "react";
import { css } from "@emotion/core";

const Skill = ({name, value}) => {
  return (
    <div>
      <div css={css`
        div {            
          width: 50%;
          display: inline-block;
          margin-bottom: 4px;
        }
      `}>
        <div>{name}</div>
        <div css={css`text-align: right;`}>{value}</div>
      </div>
      <div css={css`
        background: #ccc;
        height: 10px;
        overflow: hidden;
        margin-bottom: 16px;
        border-radius: 4px;
      `}>
        <div css={css`
          width: ${value};
          background: #0d6ec3;
          height: 10px;

          background-size: 100%;
          background-image: linear-gradient(90deg, #0d6ec3 0%, #f04444 100%);
          position: relative;
          z-index: 100;
        `} />
      </div>
    </div>
  );
};

export default ({skills}) => (
  <div css={css`
    @media (max-width: 899px) {
      h3 {
        margin-top: 2rem;
      }
    }
    @media (min-width: 900px) {
      width: 45%;
      padding-left: 32px;
    }
  `}>
    <h3>Primary Skillset</h3>

    {skills.map(({skill}, index) => {
      return (
        <Skill key={skill.name} name={skill.name} value={skill.value} />
      );
    })}
  </div>
);
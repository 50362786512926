import React from "react";
import { css } from "@emotion/core";

export default ({data}) => (
  <section css={css`
    color: #fff;
    text-align: center;
    font-size: 2.25rem;
    line-height: 1.1;
    font-weight: bold;
    text-shadow: .1875rem .1875rem .1875rem rgba(0,0,0,.1);
  `}>
    <blockquote>{data.frontmatter.quote}</blockquote>
    <div css={css`font-size: 1.62671rem;`}>— {data.frontmatter.author}</div>
  </section>
);
import React from "react";
import { css } from "@emotion/core";

export default ({bgColor, bgImage, bgTraced, children}) => {
  let bgImageStyles;
  if (bgImage && bgTraced) {
    bgImageStyles = css`
      background-color: #0d6ec3;
      background-image: url("${bgImage}"), url("${bgTraced}");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;
    `;
  }
  else {
    bgImageStyles = css`
      background: ${bgColor};
    `;
  }

  return (
    <div css={css`
      ${bgImageStyles};
      padding: 100px 0;

      @media (max-width: 959px) {
        padding: 4rem 0;
      }
      @media (min-width: 960px) {
        padding: 100px 0;
      }
    `}>
      <div css={css`
        max-width: 1140px;
        margin: 0 auto;
        
        @media (max-width: 599px) {
          padding: 0 1rem;
        }
        @media (min-width: 600px) and (max-width: 959px){
          padding: 0 2rem;
        }
        @media (min-width: 960px) {
          padding: 0 40px;
        }
        
      `}>
        {children}
      </div>
    </div>
  );
}
import React from "react";
import { css } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IconGradient from "../components/iconGradient";

export default ({data}) => {
  const facts = data.frontmatter.facts;

  return (
    <section css={css`
      color: #fff;
      text-align: center;
      font-weight: bold;
      text-shadow: .1875rem .1875rem .1875rem rgba(0,0,0,.1);

      display: flex;
      align-items: baseline;
      justify-content: center;
      
      @media (max-width: 599px) {
        flex-direction: column;
        align-items: center;
      }

    `}>
      {facts.map(({fact}, index) => {
        return (
          <div key={fact.name}
            css={css`
              display: inline-block;

              @media (max-width: 599px) {
                margin-bottom: 3rem;
              }
              @media (min-width: 600px) {
                width: ${100 / facts.length}%;
              }

              .value {
                font-size: 2rem;
                font-weight: bold;
                line-height: 1rem;
                padding-bottom: 1rem;
              }
              .name {
                font-size: 0.8em;
                text-transform: uppercase;
                font-weight: bold;
              }
            `}
          >
            <div
              css={css`
               svg {
                width: 90px;
                height: 90px;
                line-height: 90px;
                font-size: 50px;
                display: inline-block;
               }
              `}
            >
              <IconGradient idPrefix="facts"
                startColor="#f04444"
                stopColor="#0d6ec3">
                  <FontAwesomeIcon icon={['fas', fact.icon]}  className="fill-gradient-linear" />
              </IconGradient>
            </div>
            <div className="value">{fact.value}</div>
            <div className="name">{fact.name}</div>
          </div>
        );
      })}
    </section>
  );
};
import React, { Component } from "react";
import Img from "gatsby-image";
import { css } from "@emotion/core";

class Project extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      showMore: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      showMore: !prevState.showMore
    }));
  }

  render() {
    return (
      <div css={css`
        .project {
          position: relative;
          
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 959px) {
            flex-wrap: wrap;

            margin-bottom: 4rem;
          }
          @media (min-width: 960px) {
            margin-bottom: 132px;
          }


          a.button {
            display: inline-block;

            padding: 1.25rem 2rem;
            text-decoration: none;
            color: #fff;
        
            font-weight: 700;
            letter-spacing: 2px;

            box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1);
            
            border-radius: 8px;

            /* gradient transition */
            background-size: 100%;
            background-image: linear-gradient(90deg, #f04444 0%, #0d6ec3 100%);
            position: relative;
            z-index: 100;
            &:before {
              background-image: linear-gradient(90deg, #f04444 0%, #f04444 100%);
              content: "";
              display: block;
              height: 100%;
              position: absolute;
              top: 0; left: 0;
              opacity: 0;
              width: 100%;
              z-index: -100;
              border-radius: 8px;
              transition: opacity 0.25s;
            }
            &:hover {
              &:before {
                opacity: 1;
              }
            }
          }

          &:nth-of-type(odd) {
            flex-direction: row;

            .col:nth-of-type(odd) {
              @media (min-width: 960px) {
                margin-right: 64px;
              }
            }
          }
          
          &:nth-of-type(even) {
            flex-direction: row-reverse;

            .col:nth-of-type(odd) {
              @media (min-width: 960px) {
                margin-left: 64px;
              }
            }
          }
          
          .col {
            width: 100%;

            @media (max-width: 959px) {
              text-align: center;

              a.image {
                margin: 0 auto;
              }

              h3 {
                margin-top: 1.45rem;
              }
            }
          }

          a.image {
            display: block;

            max-width: 500px;
            width: 100%;

            @media(min-width: 600px) {
              padding: 4px;
            }
            
            border-radius: 8px;

            img {
              border-radius: 8px;
            }
            
            box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1);

            /* gradient transition */
            background-size: 100%;
            background-image: linear-gradient(90deg, #0d6ec3 0%, #f04444 100%);
            position: relative;
            z-index: 100;
            &:before {
              background-image: linear-gradient(90deg, #0d6ec3 0%, #0d6ec3 100%);
              content: "";
              display: block;
              height: 100%;
              position: absolute;
              top: 0; left: 0;
              opacity: 0 !important;
              width: 100%;
              z-index: -100;
              border-radius: 8px;
              transition: opacity 0.25s;
            }
            &:hover {
              &:before {
                opacity: 1 !important;
              }
            }
          }
        }

        .hide {
          display: ${this.state.showMore ? "flex" : "none"};
        }
      `}>
        {this.props.data.frontmatter.projects.map(({project}, index) => {
          return (
            <div key={project.name} className={`project ${index > 2 ? "hide" : ""}`}>
              <div className="col">
                <a href={project.link} title={project.name} className="image">
                  <Img
                    fluid={project.image.childImageSharp.fluid}
                    alt={project.name} />
                </a>
              </div>
              <div className="col">
                <h3>{project.name}</h3>
                <p>{project.description}</p>
                <a href={project.link} className="button" title={project.name}>
                  View Project
                </a>
              </div>
            </div>
          );
        })}

        <div css={css`
          text-align: center;
        `}>
          <button onClick={this.handleClick}
            css={css`
              width: 100%;
              max-width: 500px;
              border: none;
              outline: none;
              display: inline-block;
  
              cursor: pointer;

              padding: 2px;

              text-decoration: none;
              color: #fff;
          
              font-weight: 700;
              letter-spacing: 2px;
  
              box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1);
              
              border-radius: 8px;
  
              /* gradient transition */
              background-size: 100%;
              background-image: linear-gradient(90deg, #f04444 0%, #0d6ec3 100%);
              position: relative;
              z-index: 100;
              &:before {
                background-image: linear-gradient(90deg, #0d6ec3 0%, #f04444 100%);
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                top: 0; left: 0;
                opacity: 0;
                width: 100%;
                z-index: -100;
                border-radius: 8px;
                transition: opacity 0.25s;
              }

              &:hover {
                &:before {
                  opacity: 1;
                }

                span {
                  background: transparent;
                  color: #fff;

                  &.arrow {
                    &.up {
                      border-top: 12px solid #fff;
                    }
                    &.down {
                      border-bottom: 12px solid #fff;
                    }
                  }
                }
              }
            `}
          >
            <span
              css={css`
                background: #fff;
                display: inline-block;
                padding: 1.25rem 2rem;
                width: 100%;
                border-radius: 8px;
                text-transform: uppercase;
                color: rgba(0,0,0,1);

                position: relative;
                
                @media (min-width: 600px) {
                  padding-right: 64px;
                }

                transition: 0.25s background;

                span.arrow {
                  @media (max-width: 599px) {
                    display: none;
                  }

                  position: absolute;
                  right: 34%;
                  top: 50%;
                  transform: translate(-34%, -50%);
                  width: 0; 
                  height: 0; 
                  border-left: 8px solid transparent;
                  border-right: 8px solid transparent;

                  &.up {
                    border-top: 12px solid rgba(0,0,0,.8);
                  }
                  &.down {
                    border-bottom: 12px solid rgba(0,0,0,.8);
                  }
                }
              `}
            >
              Show {!this.state.showMore ? "More" : "Less"} <span className={!this.state.showMore ? "arrow up" : "arrow down"}></span>
            </span>
          </button>
        </div>
      </div>
    );
  }
}

export default ({data}) => (
  <section>
    <h2 id="projects">
      {data.frontmatter.label}
    </h2>

    <Project data={data} />
  </section>
);
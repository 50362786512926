import React, { Component } from "react";
import { css } from "@emotion/core";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IconGradient from "./iconGradient";

class Navigation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isStickyStyle: false,
      isBarsActive: false,
      aboutElement: null,
      projectsElement: null,
      contactElement: null,
      isAboutActive: false,
      isProjectsActive: false,
      isContactActive: false,
    }

    this.handleScroll = this.handleScroll.bind(this);
    this.setStickyNav = this.setStickyNav.bind(this);
    this.setActiveNav = this.setActiveNav.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getStickyTop = this.getStickyTop.bind(this);
    this.setNavClick = this.setNavClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.setStickyNav();
  }

  handleClick(e) {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        isBarsActive: !prevState.isBarsActive,
        isStickyStyle: !prevState.isBarsActive || this.getStickyTop(),
      };
    });
  }

  setNavClick(e) {
    e.preventDefault();
    this.setState({
      isBarsActive: false
    });
  }

  getStickyTop() {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    return (scrollTop > 0);
  }

  handleScroll(e) {
    this.setStickyNav();
    this.setActiveNav();
  }

  setStickyNav() {
    const isSticky = this.getStickyTop();

    this.setState((prevState) => ({
      isStickyStyle: isSticky,
    }));

    if (!isSticky && this.state.isBarsActive) {
      this.setState({
        isBarsActive: false
      });
    }
  }

  setActiveNav() {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    const offset = 125;
    if (!this.state.aboutElement || !this.state.projectsElement || !this.state.contactElement) {
      this.setState({
        aboutElement: document.getElementById("about"),
        projectsElement: document.getElementById("projects"),
        contactElement: document.getElementById("contact"),
      });
    }

    let distFromAbout = Math.floor(this.state.aboutElement.offsetTop - offset - scrollTop);
    let distFromProjects = Math.floor(this.state.projectsElement.offsetTop - offset - scrollTop);
    let distFromContact = Math.floor(this.state.contactElement.offsetTop - offset - scrollTop);

    if (distFromAbout <= 0 && distFromProjects > 0) {
      this.setState({
        isAboutActive: true,
        isProjectsActive: false,
        isContactActive: false,
      });
    }
    else if (distFromProjects <= 0 && distFromContact > 0) {
      this.setState({
        isAboutActive: false,
        isProjectsActive: true,
        isContactActive: false,
      });
    }
    else if (distFromContact <= 0) {
      this.setState({
        isAboutActive: false,
        isProjectsActive: false,
        isContactActive: true,
      });
    }
    else {
      this.setState({
        isAboutActive: false,
        isProjectsActive: false,
        isContactActive: false,
      });
    }
  }

  render() {
    return (
      <div
        css={css`
          position: fixed;
          width: 100%;
          z-index: 999;

          transition: 0.5s background;

          &.sticky {
            background: #fff;
            box-shadow: 0 .1875rem .1875rem 0 rgba(0,0,0,.1);

            #bars {
              color: #0d6ec3;

              &:hover {
                color: #f04444;
              }
            }

            a {
              color: #0d6ec3;
              border-radius: 8px;

              text-shadow: none;

              transition: 0.25s color, 0.5s padding, 0.25s background;
              
              &:hover {
                background: #F1F1F1;
                color: #f04444;
              }

              &.social:hover {
                background: #fff;
              }

              &.active {
                background: rgba(13,110,195, 0.75);
                color: #fff;
              }
            }

            a.logo {
              padding: 16px 8px;
              padding-bottom: 24px;
              color: #0d6ec3;

              @media (max-width: 767px) {
                padding-left: 0;
                padding-right: 0;
              }

              &:hover {
                background: transparent;
              }

              .logoText {
                background: linear-gradient(to right, #f04444, #0d6ec3);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              &:hover .logoText {
                background: linear-gradient(to right, #0d6ec3, #f04444);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
        `}
        id="header-nav"
        className={`${this.state.isStickyStyle ? 'sticky':'' }`}
      >
        <nav
          css={css`
            width: 100%;
            max-width: 1140px;
            
            margin: 0 auto;
            padding: 0 32px;

            background: transparent;
            
            display: flex;
            
            @media (max-width: 767px) {
              flex-direction: column;
              
              padding: 0 1rem;
            }
            @media (min-width: 768px) {
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }
            
            a {
              color: #fff;
              font-weight: 700;
              
              padding: 16px;
              margin: 8px;

              text-decoration: none;
              text-shadow: .1875rem .1875rem .1875rem rgba(0,0,0,.1);
              
              transition: 0.25s color, 0.5s padding;
              
              &:hover {
                color: #f04444;
              }

              &.logo {
                padding: 32px 8px;
                margin: 0;

                font-weight: 900;
                font-size: 1.953em;
                line-height: 1em;
              }

              &.social {
                padding: 8px;
                margin: 4px;
      
                &:hover svg path {
                  fill: #f04444;
                }
              }
            }
          `}
        >
          <div css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          `}> 
            <AnchorLink to="/#main" title="Kyle Novak" stripHash className="logo">
              <div
                css={css`
                  span.bracket {
                    font-weight: 300;

                    &.left {
                      padding-right: 8px;
                    }
                    &.right {
                      padding-left: 8px;
                    }
                  }
                `}
              >
                <span className="logoText">
                  <span className="bracket left">[</span>
                  <span>novak</span>
                  <span className="bracket right">]</span>
                </span>
              </div>
            </AnchorLink>

            <div
              id="bars"
              onClick={this.handleClick}
              css={css`
                color: #fff;
                font-size: 2rem;
                transition: 0.25s color;
                cursor: pointer;

                &:hover {
                  color: #f04444;
                }

                @media (max-width: 767px) {
                  display: inline;
                }
                @media (min-width: 768px) {
                  display: none;
                }

                svg {
                  float: right;
                }
              `}
            >
              <FontAwesomeIcon icon={['fas', 'bars']} />
            </div>
          </div>

          <div css={css`
            @media (max-width: 767px) {
              /* display: none; */

              #nav-socials {
                display: none;
              }
             
              margin-bottom: 1rem;
              
              display: ${this.state.isBarsActive ? 'block' : 'none'};
              
              a {
                display: block;
                margin-left: 0 !important;
                margin-right: 0 !important;
                text-align: center;
              }
            }
          `}>
            <span onClick={this.setNavClick}>
              <AnchorLink to="/#about" title="About" className={this.state.isAboutActive ? 'active' : ''} stripHash />
            </span>
            <span onClick={this.setNavClick}>
              <AnchorLink to="/#projects" title="Projects" className={this.state.isProjectsActive ? 'active' : ''} stripHash />
            </span>
            <span onClick={this.setNavClick}>
              <AnchorLink to="/#contact" title="Contact" className={this.state.isContactActive ? 'active' : ''} stripHash />
            </span>
            <div
              id="nav-socials"
              css={css`
                float: right;

                &::before {
                  content: "•";
                  padding: 0 8px;
                  margin-right: 12px;
                  color: #ccc;
                }
              `}
            >
              <IconGradient idPrefix="socials"
                startColor={`${this.state.isStickyStyle ? '#f04444':'#fff' }`}
                stopColor={`${this.state.isStickyStyle ? '#0d6ec3':'#fff' }`}>
                <a className="social" href={this.props.data.linkedin}
                  title="Connect with Kyle Novak on Linkedin">
                    <FontAwesomeIcon icon={['fab', 'linkedin']} className="fill-gradient-linear" />
                </a>
                <a className="social" href={this.props.data.twitter}
                  title="Follow Kyle Novak on Twitter">
                    <FontAwesomeIcon icon={['fab', 'twitter']}  className="fill-gradient-linear" />
                </a>
              </IconGradient>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navigation;
import React from "react";
import { css } from "@emotion/core";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconGradient from "./iconGradient";

export default ({data}) => (
  <footer
    css={css`
      text-align: center;

      .social {
        font-size: 2em;
        padding: 8px;
        margin: 4px;
      }
      
      .logo {
        font-weight: 700;
        text-decoration: none;
        display: inline-block;

        padding: 0;
        margin: 0;
        margin-bottom: 32px;

        font-weight: 900;
        font-size: 1.953em;
        line-height: 1em;

        color: #0d6ec3;

        &:hover {
          background: transparent;
        }

        .logoText {
          background: linear-gradient(to right, #f04444, #0d6ec3);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &:hover .logoText {
          background: linear-gradient(to right, #0d6ec3, #f04444);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        span.bracket {
          font-weight: 300;

          &.left {
            padding-right: 8px;
          }
          &.right {
            padding-left: 8px;
          }
        }
      }
    `}
  >
    <div>
      <AnchorLink to="/#main" title="Kyle Novak" stripHash className="logo">
        <span className="logoText">
          <span className="bracket left">[</span>
          <span>novak</span>
          <span className="bracket right">]</span>
        </span>
      </AnchorLink>
    </div>
    <div css={css`margin-bottom: 16px;`}>
      <IconGradient idPrefix="socials-footer"
        startColor="#f04444"
        stopColor="#0d6ec3">
        <a className="social" href={data.linkedin}
          title="Connect with Kyle Novak on Linkedin">
            <FontAwesomeIcon icon={['fab', 'linkedin']} className="fill-gradient-linear" />
        </a>
        <a className="social" href={data.twitter}
          title="Follow Kyle Novak on Twitter">
            <FontAwesomeIcon icon={['fab', 'twitter']}  className="fill-gradient-linear" />
        </a>
      </IconGradient>
    </div>
    <small>Copyright © {new Date().getFullYear()}, Kyle J. Novak</small>
  </footer>
);
import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const mapStyles = [
  {
      "featureType": "all",
      "stylers": [
          {
              "saturation": 0
          },
          {
              "hue": "#e7ecf0"
          }
      ]
  },
  {
      "featureType": "road",
      "stylers": [
          {
              "saturation": -70
          }
      ]
  },
  {
      "featureType": "transit",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "poi",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "water",
      "stylers": [
          {
              "visibility": "simplified"
          },
          {
              "saturation": -60
          }
      ]
  }
];

const GoogleMapWrapper = withScriptjs(withGoogleMap((props) => {
  const location = JSON.parse(props.location);
  const longitude = location.coordinates[0];
  const latitude = location.coordinates[1];
  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: latitude, lng: longitude }}
      defaultOptions={{
        styles: mapStyles,
        gestureHandling: 'cooperative',
      }}
    >
      {props.isMarkerShown && <Marker title={`${props.city}, ${props.state}`} position={{ lat: latitude, lng: longitude }} />}
    </GoogleMap>
  );
}));

export default ({data}) => {
  return (
    <section>
      <GoogleMapWrapper
        isMarkerShown
        city={data.frontmatter.city}
        state={data.frontmatter.state}
        location={data.frontmatter.location}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDn8dJH7N_NCbDPwlCWSiBY4mgHF9jl6WY&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </section>
  );
};